<template>
    <div v-if="hasPermission('access_business_intelligence') && this.isBusinessIntelligenceEnabled" class="section-content pt-4" :class="{ 'mt-10': $vuetify.breakpoint.xs, 'mt-n3': $vuetify.breakpoint.sm }" >
        <div class="primary-section-content pb-2">
            <hb-header full>
                <hb-page-header title="Business Intelligence" />
            </hb-header>

            <iframe class="mt-3" title="Storelocal - Sales Demo BI dashboard" width="100%" :height="setFrameHeight"
                :src="getBusinessIntelligenceLink"
                frameborder="0" allowFullScreen="true"></iframe>
        </div>

    </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
export default {
    name: "PowerBI",
    data() {
        return {
            styleObj: {
                height: '100%'
            }
        }
    },
    mounted() {
        if(!this.hasPermission('access_business_intelligence') || !this.isBusinessIntelligenceEnabled) this.$router.push('/dashboard');
    },
    computed: {
        ...mapGetters({
            hasPermission: 'authenticationStore/rolePermission',
            isBusinessIntelligenceEnabled: 'authenticationStore/isBusinessIntelligenceEnabled',
            getBusinessIntelligenceLink: 'authenticationStore/getBusinessIntelligenceLink'
        }),
        setFrameHeight() {
            let docHeight = document.body.clientHeight;
            return docHeight ? `${docHeight - 185}px;` : '80vh';
        }
    }
}
</script>

<style scoped></style>
